.bg-primary {
  background-color: rgb(141, 9, 141) !important;
}
.btn-primary {
  background-color: #8d098d !important;
}
.bg-info {
  background-color: rgb(206, 12, 157) !important;
}
.btn-info {
  background-color: #ce0c9d !important;
}
.btn-outline-primary {
  background-color: rgb(141, 9, 141) !important;
}
.text-primary {
  color: rgb(141, 9, 141) !important;
}

.navLink:hover {
  background-color: white;
  color: rgb(141, 9, 141) !important;
  border-radius: 25%;
}

.mainhero {
  background-color: "#4d018baf";
}

.hoverable {
  box-shadow: 0;
  transition: box-shadow 0.5s ease-in-out;
}

.hoverable:hover {
  box-shadow: 5px 5px 5px 5px grey;
}

@media only screen and (max-width: 768px) {
  .mainhero {
    background-color: #551381;
    background-image: linear-gradient(
        rgba(50, 2, 90, 0.85),
        rgba(50, 2, 90, 0.85)
      ),
      url(./images/mybank-homepage.jpg);
    background-position: center;
    background-size: cover;
    color: #ffffff;
    padding: 1em;
    text-align: center;
  }
}
