@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@300&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&family=Hahmlet:wght@200;400&family=Roboto+Serif:wght@300&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  overflow-x: hidden;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

#wrapper {
  padding-left: 0;
  transition: all 0.5s ease;
  z-index: 0;
}

#wrapper.toggled {
  padding-left: 250px;
}

#sidebar-wrapper {
  z-index: 200;
  position: fixed;
  left: 250px;
  width: 0;
  height: 100%;
  margin-left: -250px;
  overflow-y: auto;
  background-color: rgb(116, 8, 107) !important;
  transition: all 0.5s ease;
}

#wrapper.toggled #sidebar-wrapper {
  width: 250px;
}

#page-content-wrapper {
  width: 100%;
  position: absolute;
  padding: 15px;
}

#wrapper.toggled #page-content-wrapper {
  position: absolute;
  margin-left: 0px;
}

/* Sidebar Styles */

.sidebar-nav {
  position: absolute;
  top: 0;
  width: 250px;
  margin: 0;
  padding: 0;
  list-style: none;
}

.sidebar-nav li {
  text-indent: 20px;
  line-height: 40px;
}

.sidebar-nav li a {
  display: block;
  text-decoration: none;
  color: #ffffff;
  transition: 2s;
}

.sidebar-nav li a:hover {
  text-decoration: none;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
}
.sidebar-nav li p {
  display: block;
  text-decoration: none;
  color: #ffffff;
  transition: 2s;
}

.sidebar-nav li p:hover {
  text-decoration: none;
  color: rgb(0, 0, 0);
  background: rgb(255, 255, 255);
}

.sidebar-nav li a:active,
.sidebar-nav li a:focus {
  text-decoration: none;
}

.sidebar-nav > .sidebar-brand {
  height: 65px;
  font-size: 18px;
  line-height: 60px;
}

.sidebar-nav > .sidebar-brand a {
  color: #999999;
}

.sidebar-nav > .sidebar-brand a:hover {
  color: #fff;
  background: none;
}

.heroTitle {
  font-size: 25px;
}
.heroText {
  font-size: 30px;
}

@media (min-width: 768px) {
  #wrapper {
    padding-left: 0;
  }
  #wrapper.toggled {
    padding-left: 250px;
  }
  #sidebar-wrapper {
    width: 0;
    z-index: 0;
  }
  #wrapper.toggled #sidebar-wrapper {
    width: 250px;
  }
  #page-content-wrapper {
    padding: 20px;
    position: relative;
  }
  #wrapper.toggled #page-content-wrapper {
    position: relative;
    margin-left: 0;
  }
  .heroTitle {
    font-size: 20px;
  }
  .heroText {
    font-size: 10px;
  }
}

.card {
  position: relative;
  display: flex;
  padding: 20px;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid #d2d2dc;
  border-radius: 11px;
  box-shadow: 0px 0px 5px 0px rgb(161, 163, 164);
}

.media img {
  width: 60px;
  height: 60px;
}

.reply a {
  text-decoration: none;
}

body,
html {
  font-family: "Anton", sans-serif;
  font-family: "Hahmlet", serif;
  font-family: "Roboto Serif", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

.navItem :hover {
  color: black;
  background-color: wheat;
}
.navItem :hover p {
  color: black;
}

.homeNavItem :hover {
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

#download-receipt {
  margin: 0;
  box-sizing: border-box;
  font-family: "VT323", monospace;
  color: #1f1f1f;
}

.fixed-footer {
  position: fixed;
  bottom: 0;
}
.menu {
  width: 35px;
  height: 5px;
  background-color: white;
  margin: 6px 0;
  transition: 1s;
}
.banker {
  position: relative;
  display: inline-block;
}

.main-hero {
  height: 59vh;
  width: 100%;
}

.sub-hero {
  height: "59vh";
  width: "100%";
}

.sub-hero-text {
  position: absolute;
  bottom: 20px;
  background-color: black;
  border-radius: 5%;
  opacity: 0.8;
  color: white;
  padding-left: 20px;
  padding-right: 20px;
}

.custom-carousel-inner {
  height: 100vh;
}
.custom-hero {
  height: 100vh;
}

.banker-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  padding: 12px 16px;
  z-index: 1;
  right: 0;
}

.banker:hover .banker-content {
  display: block;
}
.logo-image {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  padding: 5px;
}
.headerContainer {
  width: 100%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  position: fixed;
  z-index: 300;
  width: 100%;
}
.header {
  height: 25px;
}
.bankHeader {
  height: 80px;
}
.title {
  color: beige;
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
  margin-right: 100px;
}
.bankTitle {
  color: beige;
  font-size: 30px;
  font-style: italic;
  font-weight: bold;
  font-family: Arial, Helvetica, sans-serif;
}
.bankSubtitle {
  color: beige;
  font-size: 20px;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.subtitle {
  color: beige;
  font-size: 20px;
  font-style: italic;
  font-family: Arial, Helvetica, sans-serif;
}
.dropdownLink {
  position: relative;
  display: inline-block;
}

.dropdownLink-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(94, 95, 121, 0.137);
  padding: 12px 16px;
  z-index: 1;
  max-height: 400px;
  overflow-y: scroll;
  float: none;
}

.dropdownLink:hover .dropdownLink-content {
  display: block;
  float: none;
}

.content {
  padding-top: 80px;
}
#root {
  width: "100%";
  height: "100%";
}
.navigation {
  width: " 20%";
  background-color: rgba(3, 5, 34, 0.829);
  height: "100%";
}
.main {
  width: " 80%";
  height: "100%";
}
.cover {
  width: "100%";
}
.card-header {
  background-color: "blue";
}
.card-footer {
  background-color: "blue";
}
@media only screen and (max-width: 750px) {
  .main-hero {
    height: 39vh;
    width: 100%;
  }
  .sub-hero {
    height: 39vh;
    width: 100%;
  }
  .sub-hero-text {
    position: absolute;
    bottom: 20px;

    background-color: black;
    border-radius: 5%;
    opacity: 0.8;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
    font-size: 12px;
  }
  .menu {
    width: 20px;
    height: 3px;
    background-color: white;
    margin: 3px 0;
  }
  .content {
    padding-top: 40px;
  }
  .register {
    padding-top: 60px;
  }
  .navigation {
    display: none;
  }
  .headerNav {
    opacity: 0;
  }
  .bankHeader {
    height: 50px;
  }
  .bankTitle {
    color: beige;
    font-size: 14px;
    font-style: italic;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .bankSubtitle {
    color: beige;
    font-size: 11px;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    margin-bottom: 0px;
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .header {
    height: 15px;
  }
  .title {
    color: beige;
    font-size: 12px;
    font-style: italic;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
  }
  .subtitle {
    color: beige;
    font-size: 9px;
    font-style: italic;
    font-family: Arial, Helvetica, sans-serif;
    padding-bottom: 10px;
  }
  .logo-image {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    padding: 1px;
  }
  .custom-carousel-inner {
    height: 40vh;
  }
  .custom-hero {
    height: 40vh;
  }
}

.bg-primary {
  background-color: rgb(141, 9, 141) !important;
}
.btn-primary {
  background-color: #8d098d !important;
}
.bg-info {
  background-color: rgb(206, 12, 157) !important;
}
.btn-info {
  background-color: #ce0c9d !important;
}
.btn-outline-primary {
  background-color: rgb(141, 9, 141) !important;
}
.text-primary {
  color: rgb(141, 9, 141) !important;
}

.navLink:hover {
  background-color: white;
  color: rgb(141, 9, 141) !important;
  border-radius: 25%;
}

.mainhero {
  background-color: "#4d018baf";
}

.hoverable {
  box-shadow: 0;
  transition: box-shadow 0.5s ease-in-out;
}

.hoverable:hover {
  box-shadow: 5px 5px 5px 5px grey;
}

@media only screen and (max-width: 768px) {
  .mainhero {
    background-color: #551381;
    background-image: linear-gradient(
        rgba(50, 2, 90, 0.85),
        rgba(50, 2, 90, 0.85)
      ),
      url(/static/media/mybank-homepage.a367880f.jpg);
    background-position: center;
    background-size: cover;
    color: #ffffff;
    padding: 1em;
    text-align: center;
  }
}

